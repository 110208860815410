/* MODAL */

.header-fixed .app-header{
    z-index: 102 !important;
    }
    .styles_modal__gNwvD{
      width:62%;
      padding: 0px;
    }
    
    .marginmessage {
      margin-top: -15px;
      margin-bottom:0;
    }
    .borderless{
      border: 0px;
    }
    .borderless tr td,
    .borderless tr th{
      border-right: 0px;
      border-left: 0px;
      padding: 3px;
    }
    .loader-outer{
      position: relative;
    }
    .overlay {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      background: #0000007a;
      z-index: 100;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
    }
    
    span.file-name {
      position: relative;
      top: -28px;
      background: #fff;
      left: 104px;
      width: calc(100% - 120px);
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
  }

  label.float-right {
    position: relative;
    top: 10px;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 2.75rem;
}
.custom-switch .custom-control-label::after{
  left: calc(-1.25rem + 2px) !important;
}
.custom-switch  .custom-control-label::after{
  left: -2.1rem !important;
}
.switch { width: 70px; height: 30px; display: inline-block; border-radius: 35px; overflow: hidden; position: relative; margin:5px 0px;}
.switch input { width: 100%; height: 100%; opacity: 0; position: absolute; top: 0px; left: 0px; z-index: 2; padding: 0px 0px; margin: 0px 0px;}
.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #eaeaea; -webkit-transition: .4s; transition: .4s;}
.slider:before { width: 22px; height: 22px; border-radius: 50%;white-space: nowrap; content: ""; position: absolute; left: 4px; bottom: 4px; background-color: #ffffff; -webkit-transition: .5s; transition: .5s; text-indent: 30px; font-size: 14px; color: #2196F3;}
.switch input:checked + .slider { background-color: #2196F3;}
.switch input:checked + .slider:before { left: calc(100% - 26px);  color: #ffffff; text-indent: -30px;}
.lablecls{display: flex; align-items: center;}
.lablecls label{margin-right: 0px; margin-bottom:0px; margin-top: 10px;}
.datepic .react-datepicker-wrapper{
  display:flex;
}

.fixWidth150 {
  min-width: 150px;
} 
.react-datepicker-wrapper {
  width: 100%;
}
@media(max-width:767.98px){
  .productListOuter{
    display: inline-block;
    width:100%;
    margin-bottom: 20px;
  }
  .app-header{
    height: initial;
  }
  .app-header ul.ml-auto.navbar-nav {
    margin: 48px 0px 0px 0px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .app-header .navbar-toggler {
    position: absolute;
    top: 12px;
    margin: 0px;
  }
  .app-header ul.ml-auto.navbar-nav a.btn.mr-2 {padding: 7px 2px; margin:0px  4px !important;}
  .app-header ul.ml-auto.navbar-nav a i {
      margin-right: 6px !important;
  }
}